import React from "react";
import styled from "styled-components";
import OrangeLogo from "./orange_logo.jpg"
import {useTranslation} from "react-i18next";

const HeadingContainer = styled.div`
    display: grid;
    grid-template-areas: 'image title .';
    grid-template-columns: 70px 1fr 15px;
    grid-template-rows: auto;
    max-width: 100%;
`;

const Image = styled.img`
    padding: 15px;
    width: calc(100% - 30px);
`;

const TitleContainer = styled.div`
    grid-area: title;
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 1.8rem;
    margin: 0;
    text-align: end;
    font-weight: 700;
    white-space: pre-line;
`;

const ImageContainer = styled.div`
    grid-area: image;
`;

const HeaderContainer = styled.div`
    grid-area: header;
    background-color: #FFFFFF;
`

const ProductPicture = styled.img`
    width: 100%;
    max-height: 300px;
    object-fit: cover;
`;

const ProductDetails = styled.div`
    display: grid;
    grid-template-areas:
        "text logo";
    grid-template-columns: 1fr 65px;
    grid-template-rows: 6rem;
    grid-template-gap: 1rem;
`;

const ProductDescription = styled.h2`
    font-size: 1.6rem;
    align-self: center;
    margin-left: 1.5rem;
    line-height: 1.2;
`;

const ProductLogo = styled.img`
    height: 5rem;
    margin: 0.5rem 1rem 0.5rem 0.5rem;
    justify-self: end;
`;

const Heading = () => {
    const {t} = useTranslation();

    return <HeadingContainer>
        <ImageContainer>
            <Image
                src={OrangeLogo}
                alt={t('Orange Logo')}
            />
        </ImageContainer>
        <TitleContainer>
            <Title>{t('titletext')}</Title>
        </TitleContainer>
    </HeadingContainer>;
};

const Header = (props) => {
    return <HeaderContainer>
        <Heading/>
        <ProductPicture alt="" src={props.product.picture}/>
        <ProductDetails>
            <ProductDescription>
                {props.product.textDescription &&
                props.product.textDescription
                    .find(description => description.language.toUpperCase() === props.i18n.language.toUpperCase())
                    .content}
            </ProductDescription>
            <ProductLogo alt="" src={props.product.logo}/>
        </ProductDetails>
    </HeaderContainer>
};

export default Header;
