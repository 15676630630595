const apiUrl = "http://192.168.0.65:8882";
const popUpDisplayTime = 5000;
const selfcareLink = "http://example.com";
const confirmButtonId = "confirmButtonId";

const config = {
    apiUrl,
    popUpDisplayTime,
    selfcareLink,
    confirmButtonId
}

export default config
