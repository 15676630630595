import {SecondaryText} from "../commons/Commons";
import React, {useContext} from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import createRedirectUrl from "../../common/createRedirectUrl";
import {GlobalContext} from "../../stores/GlobalStore";
import {useLocation} from "react-router-dom";
import parseSearchParams from "../../common/parseSearchParams";
import LanguageSelector from "./LanguageSelector";

const LanguageContainer = styled.div`
    grid-area: languages;
    display: flex;
    align-items: center;
    justify-content: center;
  `;

const SecondaryTextContainer = styled.div`
    grid-area: text;
    text-align: center;
    justify-content: center
`;

const CancelLinkContainer = styled.div`
    grid-area: cancel;
    text-decoration: underline;
    text-align: center;
    margin: 10px 0px 10px;
`;

const CancelLink = styled.a`
    text-decoration-color: black;
`;

const FooterContainer = styled.div`
    grid-template-areas: 
           "text text text text text"
           ". . cancel . languages";         
    margin: 10px 0px 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      
`;

const FooterText = styled(SecondaryText)`
    font-size: 1.2rem;
    color: black;
`;

const Footer = () => {
    const {state} = useContext(GlobalContext);
    const {t} = useTranslation();
    const location = useLocation();
    let redirectUrl;

    if (state.user.isLoaded && state.product.data) {
        redirectUrl = createRedirectUrl(state.user.data, false, state.product.data, location);
    } else {
        if (state.product.data) {
            redirectUrl = createRedirectUrl(parseSearchParams(location), false, state.product.data, location);
        } else {
            redirectUrl = '#';
        }
    }

    return <FooterContainer>
        <SecondaryTextContainer>
            <FooterText>{t('text6')}</FooterText>
        </SecondaryTextContainer>
        <CancelLinkContainer>
            <CancelLink href={redirectUrl}>
                <FooterText id="cancelLink">{t('text7')}</FooterText>
            </CancelLink>
        </CancelLinkContainer>
        <LanguageContainer>
            <LanguageSelector/>
        </LanguageContainer>
    </FooterContainer>
};

export default Footer;
