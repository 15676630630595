import React from "react";
import PaymentView from "../views/payment/PaymentView";
import OtpView from "../views/otp/OtpView";
import ErrorView from "../views/error/ErrorView";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {FitToWindow} from "../components/commons/Commons";
import LanguageSetter from "../stores/language/LanguageSetter";
import UserLoader from "../stores/user/UserLoader";
import ProductLoader from "../stores/product/ProductLoader";

export default function Router() {
    return (
        <BrowserRouter>
            <UserLoader>
                <LanguageSetter>
                    <ProductLoader>
                        <FitToWindow>
                            <Routes>
                                <Route path="consent_dcb">
                                    <Route path="payment" element={<PaymentView/>}/>
                                    <Route path="otp" element={<OtpView/>}/>
                                    <Route path="error" element={<ErrorView/>}/>
                                    <Route path="*" element={<ErrorView/>}/>
                                </Route>
                            </Routes>
                        </FitToWindow>
                    </ProductLoader>
                </LanguageSetter>
            </UserLoader>
        </BrowserRouter>
    );
}
