import React, {useContext, useState} from "react";
import {useLocation} from "react-router-dom";
import {GlobalContext} from "../../stores/GlobalStore";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {
    ActionButton,
    Container,
    Content,
    ErrorText,
    Form,
    FormInput,
    PriceText,
    PromoText,
    SecondaryTextBlack
} from "../../components/commons/Commons";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import {generateOtp} from "../../stores/otp/OtpService";
import OtpPopup from "./OtpPopup";
import getPeriodicityText from "../../common/getPeriodicityText";
import {makePayment} from "../../stores/payment/PaymentService";
import PaymentPopups from "../paymentPopups/PaymentPopups";
import {otpErrorToMessage} from "./OtpErrorMessageService";
import errorCodeToMessage from "../payment/errorCodeToMessage";

const OtpButton = styled(ActionButton)`
    margin-top: 5px;
`;

const OtpView = () => {
    const {state, dispatch} = useContext(GlobalContext);
    const {product, error} = state;
    const {language} = state.language;
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);
    const [msisdn, setMsisdn] = useState("");
    const [isInvalidInput, setInvalidInput] = useState(false);
    const [challengeId, setChallengeId] = useState("");
    const [otpErrorMessage, setOtpErrorMessage] = useState("");
    const [isOtpModalOpen, setOtpModalOpen] = useState(false);
    const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);

    const searchParams = new URLSearchParams(location.search);
    const serviceId = searchParams.get("serviceId");

    const sendOtp = async (e) => {
        e.preventDefault();
        const phoneNumber = msisdn.replaceAll(/[ -]/g, "");

        if (!isValidMSISDN(phoneNumber)) {
            setInvalidInput(true);
            return false;
        }

        setMsisdn(phoneNumber);
        setInvalidInput(false);
        setIsProcessing(true);


        const response = await generateOtp(phoneNumber, serviceId, language);
        if (!response.ok) {
            const errorJson = await response.json();
            setOtpErrorMessage(otpErrorToMessage(t, errorJson));
        } else {
            const responseJson = await response.json();
            setChallengeId(responseJson.challengeId);
            setOtpModalOpen(true);
        }
        setIsProcessing(false);
    };

    const isValidMSISDN = (phoneNumber) => {
        return /^04\d{8}$/.test(phoneNumber);
    };

    const updateMsisdn = (e) => {
        setMsisdn(e.target.value);
    };

    const onModalClose = () => {
        setOtpModalOpen(false);
    };

    const confirmPayment = async (token, antiFraudTransactionId) => {
        const paymentResponse = await makePayment(product, token, language, antiFraudTransactionId);
        setOtpModalOpen(false);
        if (paymentResponse.status === 201) {
            setSuccessModalOpen(true);
        } else {
            const errorJson = await paymentResponse.json();
            const errorCode = errorJson.errorCode;
            const externalErrorCode = errorJson.params?.externalErrorCode;
            dispatch({
                type: "SET_ERROR",
                payload: {message: errorCodeToMessage(t, externalErrorCode || errorCode)}
            });
        }
    };

    const isOneoff = () => product.data.productType === "ONEOFF";
    const isSubscription = () => product.data.productType === "SUBSCRIPTION";
    if (product.isLoaded) {
        return <Container>
            <Header product={product.data} i18n={i18n}/>
            <Content>
                <PriceText>{product.data.amount} {product.data.currency === "EUR" ? '€' : product.data.currency} {t('VAT included')} {product.data.productType !== "ONEOFF" && getPeriodicityText(t, product.data.periodicity)}</PriceText>
                <PromoText>{product.data.textPromo
                    ?.find(description => description.language.toUpperCase() === i18n.language.toUpperCase())
                    ?.content}</PromoText>
                {isOneoff() &&
                <SecondaryTextBlack>{t('text4.oneoff')}</SecondaryTextBlack>}
                {isSubscription() &&
                <SecondaryTextBlack>{t('text4.oneoff')}</SecondaryTextBlack>}
                <Form>
                    <FormInput id="msisdnInput"
                               name={"msisdn"}
                               value={msisdn}
                               onChange={e => updateMsisdn(e)}
                               placeholder={t("text5")}
                    />
                    {isInvalidInput &&
                    <ErrorText>{t('Invalid phone number')}</ErrorText>}
                    {otpErrorMessage &&
                    <ErrorText>{otpErrorMessage}</ErrorText>}
                    <OtpButton
                        id="otpSubmitButton"
                        type={"submit"}
                        disabled={isProcessing}
                        onClick={e => sendOtp(e)}
                    >{t('sendSMS')}</OtpButton>
                </Form>
            </Content>
            <Footer/>
            <OtpPopup isOpen={isOtpModalOpen}
                      confirmPayment={confirmPayment}
                      onClose={onModalClose}
                      product={product}
                      serviceId={serviceId}
                      msisdn={msisdn}
                      challengeId={challengeId}/>
            <PaymentPopups isSuccess={isSuccessModalOpen} isError={error.isError}
                           errorMessage={error.message}/>
        </Container>
    }
    return <Container>
        <PaymentPopups isSuccess={isSuccessModalOpen} isError={error.isError}
                       errorMessage={error.message}/>
    </Container>;
};

export default OtpView;
