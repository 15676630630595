import React, {useContext, useEffect, useState} from "react";
import styled from 'styled-components';
import {
    ActionButton,
    CloseButton,
    CloseIcon,
    ContentPopup,
    ErrorText,
    Form,
    FormInput,
    HeaderPopup,
    PriceText,
    PromoText,
    SecondaryTextBlack,
    SecondaryTextBlackBold
} from "../../components/commons/Commons";
import {useTranslation} from "react-i18next";
import Popup from "../../components/Popup";
import getPeriodicityText from "../../common/getPeriodicityText";
import {confirmOtp, generateOtp} from "../../stores/otp/OtpService";
import {useLocation} from "react-router-dom";
import {parseUserToken} from "../../stores/user/UserService";
import {GlobalContext} from "../../stores/GlobalStore";
import parseSearchParams from "../../common/parseSearchParams";
import {getAntiFraudScript} from "../../stores/antifraud/AntiFraudService";
import config from "../../components/config/config";
import {otpErrorToMessage} from "./OtpErrorMessageService";

const ButtonConfirm = styled(ActionButton)`
  margin: 0.5em;
`;


const OtpPopup = (props) => {
    const {dispatch, state} = useContext(GlobalContext);
    const location = useLocation();
    const {t, i18n} = useTranslation();
    const [isProcessing, setIsProcessing] = useState(false);
    const [isLockedAfterMultipleInvalidCodes, setIsLockedAfterMultipleInvalidCodes] = useState(false);
    const [otpCode, setOtpCode] = useState("");
    const [antiFraudTransactionId, setAntiFraudTransactionId] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [codeInputTouched, setCodeInputTouched] = useState(false);
    const language = state.language.language;
    const confirmButtonId = config.confirmButtonId;
    const {product, serviceId, msisdn, challengeId} = props;
    const [newChallengeId, setNewChallengeId] = useState("");
    const searchParams = parseSearchParams(location);
    const enteredCodeIsValid = otpCode.trim() !== '';

    const codeInputBlurHandler = () => {
        setCodeInputTouched(true);
    }

    useEffect(() => {
        async function fetchAntiFraudScript() {
            if (product.data.antiFraudEnabled && antiFraudTransactionId === "") {
                const antiFraudScriptResponse = await getAntiFraudScript(confirmButtonId);
                const antiFraudScriptResponseData = await antiFraudScriptResponse.json();
                setAntiFraudTransactionId(antiFraudScriptResponseData.transactionId);
                setTimeout(() => {
                    // Make sure that the script starts loading after button is displayed on the page
                    const scriptId = "a330214d-684d"
                    if (!document.getElementById(scriptId)) {
                        const script = document.createElement("script");
                        script.setAttribute("type", "text/javascript");
                        script.setAttribute("id", scriptId)
                        script.innerHTML = antiFraudScriptResponseData.script;
                        document.getElementsByTagName("body")[0].appendChild(script);
                    }
                }, 0);
            }
        }

        fetchAntiFraudScript();
    }, [product, confirmButtonId,antiFraudTransactionId]);


    const sendOtp = async (e) => {
        e.preventDefault();
        setCodeInputTouched(true);
        setErrorMessage("");
        if (!enteredCodeIsValid) {
            return;
        }
        setCodeInputTouched(false);
        setIsProcessing(true);
        const otpResponse = await confirmOtp(
            {
                ...searchParams,
                msisdn,
                confirmationCode: otpCode.trim(),
                challengeId: newChallengeId !== "" ? newChallengeId : challengeId,
                language
            });
        if (otpResponse.ok) {
            const otpJson = await otpResponse.json();
            console.log("Response token:" + otpJson.token);
            dispatch({
                type: "SET_USER",
                payload: {
                    data: await parseUserToken(otpJson.token),
                    token: otpJson.token
                }
            });
            await props.confirmPayment(otpJson.token, antiFraudTransactionId);
            setIsProcessing(false);
        } else {
            const errorJson = await otpResponse.json();
            setErrorMessage(otpErrorToMessage(t, errorJson));
            if (errorJson.errorCode==='de55248b-a629'){
                setIsLockedAfterMultipleInvalidCodes(true);
            }
            setIsProcessing(false);
        }
    };
    const generateNewOtpCode = async () => {
        const response = await generateOtp( msisdn, serviceId, language);
        const responseJson = await response.json();
        setNewChallengeId(responseJson.challengeId);
        setIsProcessing(false)
        setErrorMessage("")
        setIsLockedAfterMultipleInvalidCodes(false);
    }


    const updateOtp = (e) => {
        setOtpCode(e.target.value);
    };

    const isSubscription = () => product.data.productType === "SUBSCRIPTION";

    return <Popup
        open={props.isOpen}
        modal
        closeOnDocumentClick={false}
        nested
    >
        <div className="modal">
            <HeaderPopup>
                <CloseButton onClick={() => props.onClose()} disabled={isProcessing}>
                    <CloseIcon/>
                </CloseButton>
            </HeaderPopup>
            {product &&
                <ContentPopup>
                    <PriceText>{product.data.amount} {product.data.currency === "EUR" ? '€' : product.data.currency} {t('VAT included')} {isSubscription() ? getPeriodicityText(t, product.data.periodicity) : ""}</PriceText>
                    <PromoText>{product.data.textPromo
                        ?.find(description => description.language.toUpperCase() === i18n.language.toUpperCase())
                        ?.content}</PromoText>
                    <SecondaryTextBlack>{isSubscription() ?
                        t('text9.subscription') : t('text9.oneoff')}
                        <SecondaryTextBlackBold>{msisdn}</SecondaryTextBlackBold>.</SecondaryTextBlack>
                    <Form>
                        <FormInput id="otpCodeInput"
                                   name={"otpCode"}
                                   value={otpCode}
                                   onChange={e => updateOtp(e)}
                                   onBlur={codeInputBlurHandler}
                                   placeholder={t("text8")}
                        />
                        {errorMessage &&
                            <ErrorText>{errorMessage}</ErrorText>}
                        {!enteredCodeIsValid && codeInputTouched &&
                            <ErrorText>{t('Invalid code')}</ErrorText>}
                        <ButtonConfirm
                            id={confirmButtonId}
                            type={"submit"}
                            hidden={isLockedAfterMultipleInvalidCodes}
                            disabled={isProcessing}
                            onClick={e => sendOtp(e)}
                        >{t('confirmButton')}</ButtonConfirm>
                        <ButtonConfirm
                            id={confirmButtonId}
                            type={"button"}
                            hidden={!isLockedAfterMultipleInvalidCodes}
                            disabled={isProcessing}
                            onClick={generateNewOtpCode}
                        >{t('resendSMS')}</ButtonConfirm>
                        {isSubscription() &&
                            <SecondaryTextBlack
                                style={{"marginTop": "1rem"}}>{t('popup.subscription.info')}</SecondaryTextBlack>}
                    </Form>
                </ContentPopup>}
        </div>
    </Popup>
};

export default OtpPopup;
